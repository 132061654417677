import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { AuthUser } from 'types/user';
import { getConfig } from 'utils/config';
import { setLogRocketIdentity } from 'utils/logRocket';

export const useLogrocket = () => {
  const { user } = useAuth0<AuthUser>();
  const { environment } = getConfig();

  useEffect(() => {
    if (environment === 'local') {
      console.info(`Skipping Log Rocket initialisation in local environment`);
      return;
    }
    if (user) {
      setLogRocketIdentity(user);
    }
  }, [environment, user]);
};
