declare global {
  interface Window {
    env: any;
  }
}

export type EnvType = {
  REACT_APP_ENV: string;
  REACT_APP_AUTH0_ORGANISATION: string;
  REACT_APP_PAGE_TITLE: string;
  REACT_APP_BACKEND_HOST: string;
  REACT_APP_BRAND_ORGANISATION: string;
  REACT_APP_ENROLLER: string;
  REACT_APP_MARKETPLACE: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUTH0_CALLBACK_URL: string;
  REACT_APP_AUTH0_AUDIENCE: string;
  REACT_APP_SENTRY_DSN?: string;
  REACT_APP_FAVICON: string;
  REACT_APP_EDUCATIUS_RESOURCES_BASEPATH?: string;
  REACT_APP_LOG_ROCKET_ID?: string;
};

export const env: EnvType = { ...process.env, ...window.env };
