import { useAuth0 } from '@auth0/auth0-react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, MenuItem, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { DataTable } from 'components/DataTable/DataTable';
import { Pill } from 'components/Pill';
import { format } from 'date-fns';
import { useGet } from 'hooks/useGet';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PaginatedEnvelope } from 'types/paginatedEnvelope';
import { AuthUser } from 'types/user';
import { getUserId } from 'utils/getUserId';

import { AddStaffModal } from './AddStaffModal';

interface AgencyStaffTableRow {
  id: string;
  first_name: string;
  last_name: string;
  pricing_mode: string;
  email: string;
  roles: string[];
  additional_details: {
    marketing_slug: string;
    agency_name: string;
    agency_branch: string;
    agency_website: string;
    agency_established_date: string;
  };
  agency_id: string;
  permissions: string[];
  contracted_with_educatius: boolean;
  contracted_with_enroller: boolean;
  last_active: string;
  created_at: string;
  updated_at: string;
}

export const StaffOverview = () => {
  const theme = useTheme();
  const { user } = useAuth0<AuthUser>();
  const getTableData = useGet<PaginatedEnvelope<AgencyStaffTableRow>>(`/partner-api/agencies/${user?.userData.agencyId}/users`);
  const [isAddStaffModalOpen, setIsAddStaffModalOpen] = useState<boolean>(false);

  const closeAddStaffModal = () => {
    setIsAddStaffModalOpen(false);
    getTableData();
  };

  const columns = useMemo<MRT_ColumnDef<AgencyStaffTableRow>[]>(() => {
    return [
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        header: 'Name',
        Cell: ({ renderedCellValue, row }) => (
          <Link to={`/agencies/${row.original.agency_id}/users/${row.original.id}`} style={{ textDecoration: 'none' }}>
            <Typography sx={{ color: 'black' }}>
              {renderedCellValue}
              {row.original.id === getUserId(user!) && (
                <Typography fontWeight="bold" display="inline">
                  {' '}
                  (You)
                </Typography>
              )}
            </Typography>
            <Typography sx={{ ...theme.mixins.secondaryText }}>{row.original.email}</Typography>
          </Link>
        ),
      },
      {
        accessorKey: 'roles',
        header: 'Role',
        Cell: ({ row }) => (row.original.roles.includes('agent-agency-admin') ? <Pill label="Admin" color="success" /> : <Typography>Staff</Typography>),
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        maxSize: 50,
        size: 50,
      },
      {
        accessorFn: (row) => (row.permissions.includes('marketplace.pricing.view') ? 'Visible' : 'Hidden'),
        header: 'Pricing',
        maxSize: 50,
        size: 50,
      },
      {
        accessorFn: (row) => (row.permissions.includes('marketplace.commission.view') ? 'Visible' : 'Hidden'),
        header: 'Commission',
        maxSize: 50,
        size: 50,
      },
      {
        accessorKey: 'last_active',
        header: 'Last Login',
        Cell: ({ renderedCellValue, row }) => (
          <Stack>
            {row.original.last_active !== null ? (
              <>
                <Typography sx={{ color: 'black' }} fontSize={16}>
                  {format(new Date(row.original.last_active), 'dd MMM yyyy')}
                </Typography>
                <Typography sx={{ ...theme.mixins.secondaryText }}>{format(new Date(row.original.last_active), 'h:mm a')}</Typography>
              </>
            ) : (
              <Typography sx={{ ...theme.mixins.secondaryText }}>Never</Typography>
            )}
          </Stack>
        ),
        maxSize: 50,
        size: 50,
      },
    ];
  }, [user, theme.mixins.secondaryText]);

  const rowActions = ({ row }: { row: MRT_Row<AgencyStaffTableRow> }) => [
    <MenuItem key="edit" onClick={() => console.info('Edit' + row.original)}>
      Edit staff member
    </MenuItem>,

    <MenuItem key="disable" onClick={() => console.info('Delete')}>
      Disable
    </MenuItem>,

    <MenuItem key="delete" onClick={() => console.info('Delete')}>
      Delete
    </MenuItem>,
  ];

  const containerStyle: SxProps = {
    // TODO: review responsive design width of containers
    maxWidth: {
      xl: '80vw',
      lg: '80vw',
      md: '80vw',
      sm: '80vw',
      xs: '80vw',
    },
    flex: 1,
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <Box
        py={3}
        sx={{
          boxShadow: `0px 1px 2px 0px ${theme.palette.grey[200]}`,
        }}
      >
        <Container sx={containerStyle}>
          <Typography display="inline" variant="h4" component="h1" fontWeight="600" sx={{ ...theme.mixins.titleGradient, pl: 4 }}>
            {user?.userData.agencyName}
          </Typography>
        </Container>
      </Box>

      <Container sx={containerStyle}>
        <Box px={4} pb={4} pt={1}>
          <Stack>
            <DataTable
              tableKey="staff"
              getTableData={getTableData}
              columnDefinitions={columns}
              tableHeading="Staff"
              searchPlaceholder="Search staff"
              newButton={
                <Button variant="outlined" color="secondary" onClick={() => setIsAddStaffModalOpen(true)}>
                  Add staff <AddIcon color="secondary" sx={{ pl: 1 }} />
                </Button>
              }
              rowActions={rowActions}
            />
          </Stack>
        </Box>
      </Container>
      <AddStaffModal isOpen={isAddStaffModalOpen} onClose={closeAddStaffModal} />
    </Box>
  );
};
