import { getConfig } from 'utils/config';

import { educatiusBranding } from './educatius';
import { enrollerBranding } from './enroller';
import { greenheartBranding } from './greenheart';

const getBranding = () => {
  const { brandOrganisation } = getConfig();

  switch (brandOrganisation) {
    case 'educatius':
      return educatiusBranding;
    case 'greenheart':
      return greenheartBranding;
    case 'enroller':
    default:
      return enrollerBranding;
  }
};

export const branding = getBranding();
