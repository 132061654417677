import { useAuth0 } from '@auth0/auth0-react';
import { HelpRounded } from '@mui/icons-material';
import { AppBar, Avatar, Box, Button, Divider, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Popover, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { getConfig } from 'utils/config';
import { AuthUser } from 'types/user';
import Logo from 'assets/images/logo-enroller.svg';

export const GreenheartHeader = () => {
  const theme = useTheme();
  const { user } = useAuth0<AuthUser>();
  const { marketplaceUrl, enrolmentsUrl } = getConfig();

  const linkStyle = { ml: 2, mr: 2, color: '#414141', textDecoration: 'none', cursor: 'pointer' };
  const mpLinkStyle = {
    ...linkStyle,
    ...theme.mixins.titleGradient,
    fontWeight: 600,
    // TODO: Work out how to get this border aligned along the bottom of the AppBar
    // borderBottom: `3px solid ${theme.palette.primary.main}`,
  };
  const borderColor = grey[200];

  return (
    <AppBar sx={{ zIndex: 100, boxShadow: 0, borderBottom: '1px solid', borderColor }}>
      <Box
        sx={{
          py: 1.5,
          pl: 1.3,
          pr: 3.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#fff',
        }}
      >
        <Link href="/" sx={{ height: 26, mr: 5 }}>
          <img src={Logo} alt="Enroller" height="26px" />
        </Link>

        <Box sx={{ height: 36, display: 'flex', alignItems: 'center' }}>
          <Link sx={mpLinkStyle} href={`${marketplaceUrl}/marketing`}>
            Marketplace
          </Link>
          <Link sx={linkStyle} href={enrolmentsUrl}>
            Enrolments
          </Link>
        </Box>
        <Box flex={1} />

        <Link href="//help.enroller.co.nz/" target="_blank" rel="noopener noreferrer" sx={{ mr: 1.8, height: 32 }}>
          <HelpRounded sx={{ color: grey[800], fontSize: '32px' }} />
        </Link>
        {user ? <UserMenu /> : <></>}
      </Box>
    </AppBar>
  );
};

const UserMenu = () => {
  const { user, logout } = useAuth0<AuthUser>();
  const theme = useTheme();
  const { enrolmentsUrl } = getConfig();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const mainSchoolSlug = user!.userData.schoolSlugs[0] ?? null;
  const profilePageUrl =
    user!.userData.roles.includes('agent') || mainSchoolSlug === null ? `${enrolmentsUrl}/profile` : `${enrolmentsUrl}/${mainSchoolSlug}/profile`;

  return (
    <div>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ p: 0, height: 36 }}>
        <Avatar sx={{ background: theme.palette.primary.main, width: 32, height: 32, fontSize: 15, fontWeight: 'bold' }}>
          {user!.userData.firstName[0]}
          {user!.userData.lastName[0]}
        </Avatar>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <List>
          <Link href={profilePageUrl} target="_blank" underline="none">
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ background: theme.palette.primary.main }}>
                  {user!.userData.firstName[0]}
                  {user!.userData.lastName[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${user!.userData.firstName} ${user!.userData.lastName}`} secondary={user!.email} />
            </ListItem>
          </Link>

          <Divider />
          <ListItem>
            <ListItemText primary="" />
            <Button
              variant="outlined"
              onClick={() => {
                setAnchorEl(null);
                logout({ logoutParams: { returnTo: `${enrolmentsUrl}/logout` } });
              }}
            >
              Logout
            </Button>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
