import { Branding } from 'branding/Branding';

import { GreenheartHeader } from './GreenheartHeader';
import { GreenheartFooter } from './GreenheartFooter';

const primaryMain = '#3EAD47';

export const greenheartBranding: Branding = {
  header: <GreenheartHeader />,
  headerHeight: 7,
  footer: <GreenheartFooter />,
  fontFamily: 'Inter, sans-serif',
  paletteOptions: {
    primary: {
      main: primaryMain,
      light: '#C5E6C8',
      dark: '#039855',
      contrastText: '#ffffff',
    },
    titleGradientColor1: primaryMain,
    titleGradientColor2: '#335f33',
  },
};
